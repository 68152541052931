<!--
 * @Author:候怀烨
 * @Date: 2020-12-09 14:36:21
 * @LastEditTime: 2020-12-09 16:07:10
 * @LastEditors: Please set LastEditors
 * @Description: 集合超类，查看弹框
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\predeposit\depositactivity\components\CollectionSuperclass.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="限定业务员"
      :visible.sync="ywy"
      width="30%"
      center
    >
      <div>
        <el-table stripe :data="ywydata">
          <el-table-column
            v-for="(ywylist, index) in ywyclaose"
            :key="index"
            :label="ywylist.label"
            :align="ywylist.align"
            :prop="ywylist.prop"
            :width="ywylist.width"
          ></el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ywy = false">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="协议赠品"
      :visible.sync="zp"
      width="50%"
      center
    >
      <div>
        <el-table stripe :data="zpdata">
          <el-table-column
            v-for="(zplist, index) in zpclaose"
            :key="index"
            :label="zplist.label"
            :align="zplist.align"
            :prop="zplist.prop"
            :width="zplist.width"
          ></el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="zp = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="限定下单商品"
      :visible.sync="sp"
      width="50%"
      center
    >
      <div>
        <span>限定品牌：</span>
        <el-row :gutter="20" style="padding: 10px 0px">
          <el-col v-for="(item, indexd) in treelist" :key="indexd" :span="6">
            <el-button class="el-icon-close">
              {{ item.brand_name }}
            </el-button>
          </el-col>
        </el-row>
        <span>限定商品：</span>
        <el-table stripe :data="spdata">
          <el-table-column
            v-for="(splist, index) in spclaose"
            :key="index"
            :label="splist.label"
            :align="splist.align"
            :prop="splist.prop"
            :width="splist.width"
          >
            <!-- // ? 修改价格 -->
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sp = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    data() {
      return {
        ywy: false,
        sp: false,
        zp: false,
        ywydata: [],
        spdata: [],
        zpdata: [],
        treelist: [],
        ywyclaose: [
          {
            label: '部门',
            align: 'center',
            prop: 'depart_name',
            width: 'auto',
          },
          {
            label: '业务员姓名',
            align: 'center',
            prop: 'user_name',
            width: 'auto',
          },
          {
            label: '电话',
            align: 'center',
            prop: 'mobile',
            width: 'auto',
          },
        ],
        zpclaose: [
          {
            label: '商品名称',
            align: 'center',
            prop: 'goods_name',
            width: 'auto',
          },
          {
            label: '规格',
            align: 'center',
            prop: 'goods_specs',
            width: 'auto',
          },
          {
            label: '赠品数量',
            align: 'center',
            prop: 'goods_num',
            width: 'auto',
          },
          {
            label: '已下单',
            align: 'center',
            prop: 'goods_num_use',
            width: 'auto',
          },
        ],
        spclaose: [
          {
            label: '商品名称',
            align: 'center',
            prop: 'goods_name',
            width: 'auto',
          },
          {
            label: '规格',
            align: 'center',
            prop: 'goods_specs',
            width: 'auto',
          },
          {
            label: '单位',
            align: 'center',
            prop: 'unit_name',
            width: 'auto',
          },
          {
            label: '下单价',
            align: 'center',
            prop: 'goods_price',
            width: 'auto',
          },
        ],
        url: {
          yw: '/baseAdmin/common/user-list',
        },
      }
    },
    methods: {
      hanlderdataywy(row) {
        console.log(row)
        postAction(this.url.yw, { limit_user: row.join(',') })
          .then((res) => {
            console.log(res, '')
            this.ywydata = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      hanlderdatasp() {},
      hanlderdatazp() {},
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then((_) => {
            done()
          })
          .catch((_) => {})
      },
      createtabl() {},
    },
  }
</script>

<style></style>
